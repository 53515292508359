import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import { FormWithChildren, Select, Alert } from 'library';
import { format_time_difference } from 'functions';

class ParticipationRow extends Component {
    constructor(props) {
        super(props);

        this.mark_incomplete = this.mark_incomplete.bind(this);
    }

    mark_incomplete() {
        ajaxWrapper(
            'POST',
            `/api/home/studentassessment/${this.props.student_assessment['id']}/`,
            { completed: false },
            this.props.refresh
        );
    }

    render() {
        let student_assessment = this.props.student_assessment;

        let elapsed = 0;
        if (student_assessment['first_event']) {
            let started = new Date(student_assessment['first_event']);
            let finished = new Date();
            if (student_assessment['last_event']) {
                finished = new Date(student_assessment['last_event']);
            }

            elapsed = finished.getTime() - started.getTime();
        }

        let elapsed_jsx = <div>{`${format_time_difference(elapsed)}`}</div>;

        let incomplete_button = null;
        if (student_assessment.completed) {
            incomplete_button = (
                <td style={{ textAlign: 'right' }}>
                    <a
                        className="purple-button"
                        onClick={this.mark_incomplete}
                        style={{ color: 'white' }}
                    >
                        Mark Incomplete
                    </a>
                </td>
            );
        }

        return (
            <tr>
                <td>{student_assessment['student']['student_number']}</td>
                <td>{`${student_assessment['student']['first_name']} ${student_assessment['student']['last_name']}`}</td>
                <td>{student_assessment['assessment_name']}</td>
                <td style={{ textAlign: 'right' }}>{`${
                    student_assessment['responses_count'] || 0
                }/${student_assessment['question_count'] || 0}`}</td>
                <td style={{ textAlign: 'right' }}>{elapsed_jsx}</td>
                {incomplete_button}
            </tr>
        );
    }
}

export default class TeacherParticipation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filtered_section: null,
            filtered_assessment: null,
        };
    }

    render() {
        let assessment_sections = {
            not_started: [],
            in_progress: [],
            completed: [],
        };

        for (let item of this.props.student_assessments) {
            let student_assessment = item['studentassessment'];

            if (this.state.filtered_section) {
                let valid_students =
                    this.props.section_lookup[this.state.filtered_section][
                        'students'
                    ];
                if (
                    !valid_students.includes(
                        student_assessment['student']['id']
                    )
                ) {
                    continue;
                }
            }
            if (this.state.filtered_assessment) {
                if (
                    student_assessment['assessment_id'] !=
                    this.state.filtered_assessment
                ) {
                    continue;
                }
            }

            if (student_assessment.completed) {
                // Completed
                assessment_sections['completed'].push(
                    <ParticipationRow
                        student_assessment={student_assessment}
                        refresh={this.get_participation}
                    />
                );
            } else {
                //Started
                assessment_sections['in_progress'].push(
                    <ParticipationRow student_assessment={student_assessment} />
                );
            }
        }
        for (let item of this.props.students_without_assessment) {
            let student = item['districtuser'];

            if (this.state.filtered_section) {
                let valid_students =
                    this.props.section_lookup[this.state.filtered_section][
                        'students'
                    ];
                if (!valid_students.includes(student['id'])) {
                    continue;
                }
            }

            assessment_sections['not_started'].push(
                <ParticipationRow student_assessment={{ student: student }} />
            );
        }

        let table_headers = (
            <tr>
                <th width="20">Student ID</th>
                <th width="20">Student Name</th>
                <th width="20">Assessment</th>
                <th width="20" style={{ textAlign: 'right' }}>
                    Question Complete
                </th>
                <th width="20" style={{ textAlign: 'right' }}>
                    Elapsed Time (MM:SS)
                </th>
            </tr>
        );
        let completed_table_headers = (
            <tr>
                <th width="18">Student ID</th>
                <th width="18">Student Name</th>
                <th width="18">Assessment</th>
                <th width="18" style={{ textAlign: 'right' }}>
                    Question Complete
                </th>
                <th width="18" style={{ textAlign: 'right' }}>
                    Elapsed Time (MM:SS)
                </th>
                <th width="10" style={{ textAlign: 'right' }}>
                    Elapsed Time (MM:SS)
                </th>
            </tr>
        );

        let filters = (
            <FormWithChildren
                autoSetGlobalState
                globalStateName="filter"
                setGlobalState={(name, state) => this.setState(state)}
            >
                <Select
                    label="Class"
                    name="filtered_section"
                    options={this.props.section_options}
                    style={{
                        width: '200px',
                        display: 'inline-block',
                        marginRight: '10px',
                    }}
                />
                <Select
                    label="Assessment"
                    name="filtered_assessment"
                    options={this.props.assessment_options}
                    style={{ width: '200px', display: 'inline-block' }}
                />
            </FormWithChildren>
        );

        let elapsed =
            this.props.current_date.getTime() - this.props.open_date.getTime();

        let no_students_error = null;
        let participation_content = null;
        if (
            assessment_sections['not_started'].length == 0 &&
            assessment_sections['in_progress'].length == 0 &&
            assessment_sections['completed'].length == 0
        ) {
            no_students_error = (
                <Alert
                    type="warning"
                    text="No Students were found in your classes.  If you are actively teaching classes, please
                    contact Math Anex Support at MathANEXsupport@amplify.com."
                />
            );
        } else {
            participation_content = (
                <div className="col-12">
                    <div>
                        <div className="header h2">
                            Students who have not started an assessment
                        </div>
                        <table className="table simple-card-table">
                            {table_headers}
                            {assessment_sections['not_started']}
                        </table>
                    </div>
                    <div>
                        <div className="header h2">
                            Students who are currently working through an
                            assessment
                        </div>
                        <table className="table simple-card-table">
                            {table_headers}
                            {assessment_sections['in_progress']}
                        </table>
                    </div>
                    <div>
                        <div className="header h2">
                            Students who have finished an assessment
                        </div>
                        <table className="table simple-card-table">
                            {completed_table_headers}
                            {assessment_sections['completed']}
                        </table>
                    </div>
                </div>
            );
        }

        return (
            <div className="row col-12">
                <div className="col-12">
                    <div style={{ float: 'right' }}>{filters}</div>
                    <div className="header h1">Assessment Participation</div>
                    <div>
                        {`This page has been open for ${format_time_difference(
                            elapsed
                        )}`}
                        <a
                            className="purple-button-block"
                            href="."
                            style={{
                                marginLeft: '10px',
                            }}
                        >
                            Refresh Data
                        </a>
                    </div>
                    <div>
                        <br />
                    </div>
                </div>

                {no_students_error}
                {participation_content}
            </div>
        );
    }
}
