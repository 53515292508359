import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import {
    Container,
    Alert,
    FormWithChildren,
    Button,
    TextArea,
    Wrapper,
    PageBreak,
} from 'library';
import { formatDateString, UserValidator } from 'functions';

import ReviewQuestions from 'pages/proctor/review_questions.js';
import Validation from 'pages/proctor/validation.js';
import TEXT_DICT from './translations.js';
import QuestionDisplay from './question_display.js';
import QuestionForm from './question_form.js';
import Nav from './nav.js';

import DesmosCalculator from './desmos_calculator.js';

export default class Proctor extends Component {
    constructor(props) {
        super(props);

        this.last_focus_event = null;

        this.state = {
            assessment_options: [],

            zoom: 1,

            assessment: null,
            questions: [],
            question_answers: {},
            skipped_questions: [],
            cached_context: null,

            student: null,
            current_question: null,
            assessment_completed: false,
            assessment_completed_once: false,

            running_event_details: [],

            selected_language: this.props.selected_language || null,
            language_options: ['English', 'Spanish', 'Vietnamese', 'Tagalog'],
            assessment_language_options: [],

            student_lookup_form_callback: null,

            calculator_open: false,
            submitted: false,
        };

        this.set_language = this.set_language.bind(this);
        this.change_zoom = this.change_zoom.bind(this);
        this.toggle_calculator_sidebar =
            this.toggle_calculator_sidebar.bind(this);
        this.track_event_details = this.track_event_details.bind(this);
        this.submit_event = this.submit_event.bind(this);
        this.submit_event_callback = this.submit_event_callback.bind(this);
        this.track_focus = this.track_focus.bind(this);

        this.load_context = this.load_context.bind(this);
        this.load_student_assessment = this.load_student_assessment.bind(this);
        this.set_student_assessment_complete =
            this.set_student_assessment_complete.bind(this);

        this.set_question = this.set_question.bind(this);
        this.skip_to_review_page = this.skip_to_review_page.bind(this);
        this.check_for_skipped_questions =
            this.check_for_skipped_questions.bind(this);

        this.submit_question = this.submit_question.bind(this);
        this.submit_question_callback =
            this.submit_question_callback.bind(this);
        this.submit_explanation_callback =
            this.submit_explanation_callback.bind(this);

        this.track_scratch_changes = this.track_scratch_changes.bind(this);
        this.check_selected_language_is_supported =
            this.check_selected_language_is_supported.bind(this);
    }

    componentDidMount() {
        window.addEventListener('focus', this.track_focus);
        window.addEventListener('blur', this.track_focus);
    }

    set_language(lang) {
        this.setState(
            { selected_language: lang },
            this.check_selected_language_is_supported
        );
    }

    check_languages(language_options, options) {
        for (const key in options) {
            if (!options[key]) {
                continue;
            }

            if (!language_options[key]) {
                language_options[key] = 0;
            }

            language_options[key] += 1;
        }

        return language_options;
    }

    load_context(state) {
        this.setState(state, this.check_selected_language_is_supported);
    }

    check_selected_language_is_supported() {
        let state = {
            supported_language_error: false,
        };

        // Raise error if selected language is no supported
        if (
            this.state.selected_language &&
            this.state.assessment_language_options.length > 0 &&
            !this.state.assessment_language_options.includes(
                this.state.selected_language
            )
        ) {
            state['supported_language_error'] = true;
        }

        this.setState(state);
    }

    load_student_assessment(new_state) {
        this.setState(new_state, () => this.submit_event('loaded'));
    }

    change_zoom(direction) {
        let { zoom } = this.state;

        if (direction == 'in') {
            zoom += 0.1;
        } else {
            zoom -= 0.1;
        }

        document.body.style.zoom = `${zoom * 100}%`;
        this.setState({ zoom });
    }

    toggle_calculator_sidebar(open) {
        this.setState({
            calculator_open: !this.state.calculator_open,
        });
    }

    track_event_details(data) {
        const timestamp = Date.now();
        data.timestamp = timestamp;

        const { running_event_details } = this.state;
        running_event_details.push(data);

        this.setState({
            running_event_details,
        });
    }

    submit_event(type, final_details) {
        let current_question_id = null;
        if (this.state.current_question) {
            current_question_id = this.state.current_question.id;
        }
        const { running_event_details } = this.state;
        if (typeof final_details !== 'undefined') {
            const timestamp = Date.now();
            final_details.timestamp = timestamp;
            running_event_details.push(final_details);
        }

        const data = {
            assessment: this.state.student_assessment.id,
            question: current_question_id,
            type,
            additional_details: running_event_details,
        };

        ajaxWrapper(
            'POST',
            '/proctor/event/',
            data,
            this.submit_event_callback
        );

        this.setState({
            running_event_details: [],
        });
    }

    submit_event_callback(value) {
        console.log(value);
    }

    track_focus(event) {
        if (event.target.tagName == 'BUTTON') {
            return false;
        }
        if (
            event.target.tagName == 'INPUT' ||
            event.target.tagName == 'TEXTAREA'
        ) {
            console.log('INPUT EVENT', event.type);
            this.track_event_details({
                type: `${String(event.type)} input`,
                name: event.target.name,
            });

            event.stopPropagation();
            return false;
        }

        this.track_event_details({
            type: event.type,
        });
    }

    set_question(number) {
        if (typeof number === 'undefined' || number < 0) {
            number = 0;
        }

        if (
            this.state.current_question &&
            !(this.state.current_question.id in this.state.question_answers)
        ) {
            this.submit_event('question', { type: 'skip' });
        } else if (
            this.state.current_question &&
            this.state.running_event_details.length > 0
        ) {
            this.submit_event('question', { type: 'close' });
        }

        const question = this.state.questions[number];
        if (typeof question === 'undefined') {
            this.check_assessment_complete(this.state.question_answers);
        }

        const skipped_questions = this.check_for_skipped_questions(number);

        let assessment_completed = false;
        if (question == undefined) {
            assessment_completed = true;
        }

        this.setState(
            {
                current_question: question,
                skipped_questions,
                assessment_completed,
            },
            () => this.track_event_details({ type: 'open' })
        );

        if (assessment_completed) {
            this.setState({ assessment_completed_once: true });
        }
    }

    check_for_skipped_questions(number) {
        const skipped_questions = [];
        for (let index = 0; index < number; index++) {
            const skipped_question = this.state.questions[index];
            if (skipped_question.answer_type == 'no_answer') {
                continue;
            }
            if (
                !(skipped_question.id in this.state.question_answers) ||
                !this.state.question_answers[skipped_question.id].answer
            ) {
                skipped_questions.push(skipped_question);
            } else if (
                skipped_question.explanation_question &&
                !(
                    skipped_question.explanation_question.id in
                    this.state.question_answers
                )
            ) {
                skipped_questions.push(skipped_question);
            }
        }

        return skipped_questions;
    }

    submit_question(state) {
        console.log('Submitting Question', state);
        const current_question_index = this.state.questions.indexOf(
            this.state.current_question
        );

        const has_answer = 'answer' in state && state.answer;
        const has_explanation =
            this.state.current_question.explanation_question &&
            'explanation' in state;
        const had_explination =
            this.state.current_question.explanation_question &&
            this.state.current_question.explanation_question.id in
                this.state.question_answers;
        const answer_changed =
            !(this.state.current_question.id in this.state.question_answers) ||
            !(
                state.answer ==
                this.state.question_answers[this.state.current_question.id]
                    .answer
            );
        const has_explanation_and_changed =
            !had_explination ||
            state.explanation !=
                this.state.question_answers[
                    this.state.current_question.explanation_question.id
                ].answer;

        // Check if question has no answer yet
        //Submit answer regardless
        if (!has_answer && !has_explanation) {
            console.log('Question Skipped');
            //this.set_question(current_question_index + 1);
            //return false;
        }

        // Check if question's previous answer has changed
        if (!(answer_changed || has_explanation_and_changed)) {
            console.log('Question Answer Not Changed');
            this.set_question(current_question_index + 1);
            return false;
        }

        console.log('submit_question', state);
        var url = '/proctor/question_response/';
        let scratch_work = '';
        if (this.state.current_question.id in this.state.question_answers) {
            scratch_work =
                this.state.question_answers[this.state.current_question.id]
                    .scratch_work;

            if (
                this.state.question_answers[this.state.current_question.id].id
            ) {
                url += `${
                    this.state.question_answers[this.state.current_question.id]
                        .id
                }/`;
            }
        }

        var data = {
            question: this.state.current_question.id,
            student_assessment: this.state.student_assessment.id,

            answer: '',
            response_length: 0,

            scratch_work: scratch_work,
        };

        if (has_answer) {
            data.answer = state.answer;
            data.response_length = state.answer.length;
        }

        ajaxWrapper('POST', url, data, this.submit_question_callback);

        const final_details = { type: 'submit', answer: state.answer };

        if (this.state.current_question.explanation_question) {
            if (!has_explanation) {
                state.explanation = '';
            }

            var url = '/proctor/question_response/';
            if (
                this.state.current_question.explanation_question.id in
                this.state.question_answers
            ) {
                url += `${
                    this.state.question_answers[
                        this.state.current_question.explanation_question.id
                    ].id
                }/`;
            }
            var data = {
                question: this.state.current_question.explanation_question.id,
                student_assessment: this.state.student_assessment.id,

                answer: state.explanation,
                non_explanation_answer: state.answer,
                response_length: state.explanation.length,
            };

            ajaxWrapper('POST', url, data, this.submit_explanation_callback);
            final_details.explanation = state.explanation;
        }

        this.submit_event('question', final_details);
    }

    submit_question_callback(value) {
        console.log('Question Return', value);
        // Save submitted responses
        const { question_answers } = this.state;
        question_answers[value[0].studentquestionresponse.question_id] =
            value[0].studentquestionresponse;

        const current_question_index = this.state.questions.indexOf(
            this.state.current_question
        );

        if (!this.check_assessment_complete(question_answers)) {
            this.setState(
                {
                    question_answers,
                },
                () => this.set_question(current_question_index + 1)
            );
        }
    }

    check_assessment_complete(question_answers) {
        const total_question_answers_expected =
            this.state.assessment.questions_on_assessments.length;

        if (
            Object.keys(question_answers).length ==
                total_question_answers_expected &&
            !new UserValidator().is_staff()
        ) {
            // Complete the assessment
            // Only skip questions for non-staff
            // TODO: MAKE SURE THIS IS STILL TESTABLE
            this.setState(
                {
                    question_answers,
                    current_question: null,
                    assessment_completed: true,
                },
                () => this.submit_event('completed')
            );

            return true;
        }
        return false;
    }

    submit_explanation_callback(value) {
        // Save submitted responses
        const { question_answers } = this.state;
        question_answers[value[0].studentquestionresponse.question_id] =
            value[0].studentquestionresponse;

        const current_question_index = this.state.questions.indexOf(
            this.state.current_question
        );
        const skipped_questions = this.check_for_skipped_questions(
            current_question_index
        );

        this.setState({
            question_answers,
            skipped_questions,
        });
    }

    track_scratch_changes(name, state) {
        const current_answers = this.state.question_answers;
        if (!(this.state.current_question.id in current_answers)) {
            current_answers[this.state.current_question.id] = {};
        }

        current_answers[this.state.current_question.id].scratch_work =
            state.scratch_work;

        this.setState({
            question_answers: current_answers,
        });

        this.track_event_details({ type: 'input', value: state });
    }

    skip_to_review_page() {
        this.setState(
            {
                current_question: undefined,
                assessment_completed: true,
            },
            () => this.track_event_details({ type: 'open' })
        );
    }

    set_student_assessment_complete() {
        ajaxWrapper(
            'POST',
            `/proctor/complete_student_assessment/${this.state.student_assessment.id}/`,
            {},
            () => {}
        );

        let student_assessment = this.state.student_assessment;
        student_assessment.completed = true;
        this.setState({
            student_assessment: student_assessment,
        });
    }

    render() {
        const lang = this.state.selected_language;

        let staff_button = null;
        if (new UserValidator().is_staff()) {
            staff_button = (
                <Button
                    type="success"
                    text={`(Staff Only) ${TEXT_DICT['Begin Assessment'][lang]}`}
                    onClick={() => this.set_question(0)}
                />
            );
        }

        if (this.state.assessment_error) {
            return (
                <div>
                    <Nav assessment={this.state.assessment} />
                    <Container>
                        <div style={{ marginTop: '5px' }}>
                            <Alert
                                type="danger"
                                text={this.state.assessment_error}
                            />
                        </div>
                    </Container>
                </div>
            );
        }

        let skipped_only = true;

        const container_style = {
            background: 'white',
            boxShadow: '2px 2px 10px rgba(0,0,0,.2)',
            padding: '0px',
            position: 'relative',
        };

        const wrapper_style = {
            background: '#fafafa',
        };

        let error = null;
        if (this.state.error) {
            error = (
                <div style={{ marginTop: '5px' }}>
                    <Alert type="danger" text={this.state.error} />
                </div>
            );
        }

        var student_welcome = null;
        const student_lookup = null;
        const question_header = null;
        let question_display = null;
        let question_form = null;
        let calculator = null;
        let scratch_work = null;
        let completion_confirmation = null;

        const current_question_index = this.state.questions.indexOf(
            this.state.current_question
        );

        var prev_question = null;
        if (current_question_index > 0) {
            var prev_question = (
                <Button
                    key="previous-question"
                    type="warning"
                    text={TEXT_DICT['Previous Question'][lang]}
                    onClick={() =>
                        this.set_question(current_question_index - 1)
                    }
                />
            );
        }

        if (
            !this.state.student_assessment ||
            !lang ||
            this.state.supported_language_error
        ) {
            var student_welcome = (
                <Validation
                    key="student_validation"
                    //Language details
                    selected_language={this.state.selected_language}
                    set_language={this.set_language}
                    supported_language_error={
                        this.state.supported_language_error
                    }
                    language_options={this.state.language_options}
                    //General Context
                    district_id={this.props.district_id}
                    district={this.state.district}
                    assessment_id={this.props.assessment_id}
                    student_id={this.props.student_id}
                    alternative_identifier={this.props.alternative_identifier}
                    //Cache to avoid second load_context call for some flows
                    cached_context={this.state.cached_context}
                    //Event triggers
                    load_context={this.load_context}
                    track_event_details={this.track_event_details}
                    load_student_assessment={this.load_student_assessment}
                />
            );
        } else if (this.state.assessment_completed) {
            completion_confirmation = (
                <div>
                    <Alert type="success">
                        <h3>
                            {this.state.student_assessment.completed
                                ? TEXT_DICT[
                                      'Your assessment has been submitted successfully! You can now close your tab.'
                                  ][lang]
                                : TEXT_DICT[
                                      'Thank you for completing this assessment!  Feel free to revisit any questions, or close this tab.'
                                  ][lang]}
                        </h3>
                    </Alert>
                </div>
            );
            skipped_only = false;
        } else if (
            !this.state.current_question &&
            this.state.skipped_questions.length > 0
        ) {
            completion_confirmation = (
                <div>
                    <Alert type="info">
                        <h3>
                            {
                                TEXT_DICT[
                                    'You have reached the end of this assessment.  Feel free to revisit any questions, or close this tab to complete the assessment.'
                                ][lang]
                            }
                        </h3>
                    </Alert>
                </div>
            );
            skipped_only = false;
        } else if (!this.state.current_question) {
            // <h3>{TEXT_DICT['Student found successfully'][lang]}</h3>
            const { assessment, student_assessment } = this.state;
            const date_now = new Date();
            student_welcome = (
                <div>
                    {assessment &&
                        student_assessment &&
                        // Assessment has been graded
                        (assessment.completed ? (
                            <div>
                                <Alert type="danger">
                                    <h3>
                                        {
                                            TEXT_DICT['error_already_graded'][
                                                lang
                                            ]
                                        }
                                        {assessment.date_window_closes ? (
                                            <div>
                                                <br />
                                                {TEXT_DICT['End Date: '][lang] +
                                                    formatDateString(
                                                        assessment.date_window_closes,
                                                        'mm/dd/yyyy'
                                                    )}
                                            </div>
                                        ) : null}
                                    </h3>
                                    <Button
                                        type="primary"
                                        href="mailto:MathANEXsupport@amplify.com"
                                        text={'Contact Support'}
                                    />
                                    {staff_button}
                                </Alert>
                            </div>
                        ) : // Student already took assessment
                        student_assessment.completed ? (
                            <div>
                                <Alert type="danger">
                                    <h3>
                                        {
                                            TEXT_DICT[
                                                'error_already_completed'
                                            ][lang]
                                        }
                                    </h3>{' '}
                                    <Button
                                        type="primary"
                                        href="mailto:MathANEXsupport@amplify.com"
                                        text={'Contact Support'}
                                    />
                                    {staff_button}
                                </Alert>
                            </div>
                        ) : // Student taking assessment in proper window
                        new Date(assessment.date_window_opens) < date_now &&
                          (date_now < new Date(assessment.date_window_closes) ||
                              !assessment.date_window_closes) ? (
                            <div>
                                <br />
                                <h3>{TEXT_DICT['Welcome!'][lang]}</h3>
                                <h4>
                                    {TEXT_DICT['Following Questions'][lang]}
                                </h4>
                                <h4>{TEXT_DICT['Questions Range'][lang]}</h4>
                                <br />
                                <Button
                                    type="success"
                                    text={TEXT_DICT['Begin Assessment'][lang]}
                                    onClick={() => this.set_question(0)}
                                />
                            </div>
                        ) : // Student taking assessment before window (Staff exception)
                        new Date(assessment.date_window_opens) > date_now ? (
                            <div>
                                <Alert type="warning">
                                    <h3>
                                        {
                                            TEXT_DICT['warning_before_window'][
                                                lang
                                            ]
                                        }
                                        <br />
                                        <br />
                                        {TEXT_DICT['Start Date: '][lang] +
                                            formatDateString(
                                                assessment.date_window_opens,
                                                'mm/dd/yyyy'
                                            )}
                                    </h3>
                                </Alert>
                                <div>
                                    <Button
                                        type="primary"
                                        href="mailto:MathANEXsupport@amplify.com"
                                        text={'Contact Support'}
                                    />
                                    {staff_button}
                                </div>
                            </div>
                        ) : // Student taking assessment after window but before grading (warning)
                        assessment.date_window_closes &&
                          new Date(assessment.date_window_closes) < date_now &&
                          !assessment.completed ? (
                            <div>
                                <Alert type="warning">
                                    <h3>
                                        {
                                            TEXT_DICT['warning_outside_window'][
                                                lang
                                            ]
                                        }
                                        <br />
                                        <br />
                                        {TEXT_DICT['End Date: '][lang] +
                                            formatDateString(
                                                assessment.date_window_closes,
                                                'mm/dd/yyyy'
                                            )}
                                    </h3>
                                </Alert>
                                <div>
                                    <Button
                                        type="success"
                                        text={
                                            TEXT_DICT['Begin Assessment'][lang]
                                        }
                                        onClick={() => this.set_question(0)}
                                    />
                                </div>
                            </div>
                        ) : (
                            // Else, for whatever reason, if we can't catch any condition, let them take assesement
                            <div>
                                <br />
                                <h3>{TEXT_DICT['Welcome!'][lang]}</h3>
                                <h4>
                                    {TEXT_DICT['Following Questions'][lang]}
                                </h4>
                                <h4>{TEXT_DICT['Questions Range'][lang]}</h4>
                                <br />
                                <Button
                                    type="success"
                                    text={TEXT_DICT['Begin Assessment'][lang]}
                                    onClick={() => this.set_question(0)}
                                />
                            </div>
                        ))}
                </div>
            );
        } else {
            // question_header = <h2>{TEXT_DICT['Question'][lang] + ' ' + String(current_question_index + 1)}</h2>;
            if (this.state.current_question.answer_type == 'no_answer') {
                // question_header = <h2>{TEXT_DICT['Context'][lang]}</h2>;
            } else {
                const calc_key = `${this.state.current_question.id}_calc`;
                calculator = (
                    <DesmosCalculator
                        key={calc_key}
                        track_event_details={this.track_event_details}
                    />
                );

                const scratch_defaults = {};
                if (
                    this.state.current_question.id in
                    this.state.question_answers
                ) {
                    scratch_defaults.scratch_work =
                        this.state.question_answers[
                            this.state.current_question.id
                        ].scratch_work;
                }
                const scratch_form_key = `${this.state.current_question.id}_scratch`;
                scratch_work = (
                    <div
                        className="sticky-note"
                        style={{ marginTop: '15px', padding: '6px 15px' }}
                    >
                        <FormWithChildren
                            key={scratch_form_key}
                            defaults={scratch_defaults}
                            autoSetGlobalState
                            setGlobalState={this.track_scratch_changes}
                        >
                            <TextArea
                                rows="6"
                                name="scratch_work"
                                label={
                                    <div>
                                        {TEXT_DICT['Scratch Work'][lang]}
                                        <br />(
                                        {TEXT_DICT['Optional To Use'][lang]})
                                    </div>
                                }
                                autocomplete="off"
                                style={{ padding: '0px 15px' }}
                            />
                        </FormWithChildren>
                    </div>
                );
            }

            question_display = (
                <QuestionDisplay
                    key={this.state.current_question.id}
                    language={lang}
                    question={this.state.current_question}
                />
            );

            question_form = (
                <QuestionForm
                    prev_question={prev_question}
                    key={this.state.current_question.id}
                    language={lang}
                    question={this.state.current_question}
                    question_answers={this.state.question_answers}
                    track_event_details={this.track_event_details}
                    submit_question={this.submit_question}
                />
            );

            if (this.state.calculator_open) {
                wrapper_style.marginRight = '520px';
            }
        }

        const preloading_images = [];
        for (let i = 0; i < this.state.questions.length; i++) {
            if (
                'English' in this.state.questions[i].images_by_language &&
                this.state.questions[i].images_by_language.English
            ) {
                preloading_images.push(
                    <img
                        src={this.state.questions[i].images_by_language.English}
                        style={{ height: '1px', width: '1px' }}
                    />
                );
            }
        }

        const skipped_questions_jsx = (
            <ReviewQuestions
                student_assessment={this.state.student_assessment}
                setSubmitted={this.set_student_assessment_complete}
                questions={this.state.questions}
                skipped_questions={this.state.skipped_questions}
                question_answers={this.state.question_answers}
                set_question={this.set_question}
                skipped_only={skipped_only}
                language={lang}
                skip_to_review_page={this.skip_to_review_page}
                assessment_completed={this.state.assessment_completed}
                completed_once={this.state.assessment_completed_once}
            />
        );

        let forms = [<div>{question_form}</div>];
        if (calculator) {
            forms.push(<PageBreak style={{ margin: '25px 0px' }} />);
            forms.push(<div>{calculator}</div>);
        }

        if (
            this.state.assessment &&
            this.state.assessment.calculator_allowed == false
        ) {
            forms = [
                <div className="col-12 order-first">{question_form}</div>,
                <div className="col-12 col-md-12 order-last">
                    {scratch_work}
                </div>,
            ];
            scratch_work = null;
        }

        const content = (
            <div
                style={wrapper_style}
                onBlur={this.track_focus}
                onFocus={this.track_focus}
            >
                <Nav
                    district={this.state.district}
                    assessment={this.state.assessment}
                    selected_language={this.state.selected_language}
                />

                <Container min_height style={container_style}>
                    <div className="row">
                        <div className="col-12">
                            <div style={{ height: '15px' }} />
                            {error}
                            {student_welcome}
                        </div>
                        <div className="col-12">{completion_confirmation}</div>
                        <div className="col-12 col-lg-7">
                            {question_header}
                            {question_display}
                            <div>{scratch_work}</div>
                        </div>

                        <div className="col-12 col-lg-5">{forms}</div>

                        <div className="col-12">
                            {skipped_questions_jsx}
                            <div style={{ height: '50px' }} />
                            {preloading_images}
                        </div>
                    </div>

                    <div
                        style={{
                            padding: '10px 15px',
                            position: 'absolute',
                            bottom: 0,
                            fontSize: 10,
                        }}
                    >
                        By using this site, you agree to our{' '}
                        <a href="/terms/" target="_blank">
                            Terms of Service
                        </a>
                    </div>
                </Container>
            </div>
        );

        return (
            <Wrapper
                style={{ marginTop: '0px', padding: '0px' }}
                content={content}
                loaded={!this.state.loading}
            />
        );
    }
}
