import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';
import format_date_string from 'functions/dates/format_date_string';
import { prettifyString } from 'functions';
import SideNavbar from 'pages/reports/components/side_navbar.js';
import Header from 'pages/reports/components/header.js';

import QRCode from "react-qr-code";

import { BASE_DOMAIN } from 'constants';
import { Container, Button, Alert, Tabs, Wrapper } from 'library';

function first_word(fullString){
    let firstWord = fullString.replace(/ .*/,'');
    return firstWord
}
class AssessmentAdministrationGuide extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            loading: false,
            district: {},
            height: 0,
            width: 0,
        };

        this.get_district_callback = this.get_district_callback.bind(this);

    }

    get_district_callback(result) {
        this.setState(
            {
                loaded: true,
                district: result[0].district
            },
        );
    }

    componentDidMount() {
        this.update_dimensions();
        window.addEventListener('resize', this.update_dimensions.bind(this));

        ajaxWrapper(
            'GET',
            `/admin_guide_context/${this.props.district_id}/`,
            {},
            this.get_district_callback
        );
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.update_dimensions.bind(this));
    }

    update_dimensions() {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight - 1,
        });
    }

    

    render() {

        var content = null;


        if (this.state.loaded) {
            let rostering_source = null;
            if (this.state.district.clever_id) {
                rostering_source = "Clever";
            }
            else if (this.state.district.classlink_id) {
                rostering_source = "ClassLink";
            }
            let proctor_url = "/p/" + this.state.district.proctor_url_name;
            let full_proctor_url = BASE_DOMAIN + proctor_url
            let show_window = false;
            let assessment_name = "Assessment";
            let date_window_opens = null;
            let date_window_closes = null;
            if (this.state.district.assessments.length > 0) {
                let date_window_opens_candidate = this.state.district.assessments[0].assessment.date_window_opens;
                let date_window_closes_candidate = this.state.district.assessments[0].assessment.date_window_closes;
                show_window = this.state.district.assessments.every(candidate => candidate.assessment.date_window_opens == date_window_opens_candidate && candidate.assessment.date_window_closes == date_window_closes_candidate)
                date_window_opens = format_date_string(date_window_opens_candidate,"mm/dd");
                date_window_closes = format_date_string(date_window_closes_candidate,"mm/dd");
                let assessment_name_candidates = this.state.district.assessments.map(row => first_word(row.assessment.name));
                let assessment_name_candidate = assessment_name_candidates[0];
                assessment_name = assessment_name_candidates.every(candidate => candidate == assessment_name_candidate) ? assessment_name_candidate : "Assessment";
            }    

            var content = (
                <Container className="administration-guide" no_header style={{ padding: '0px', marginBottom: '50px' }}>
                    <div className="simple-card no-print">
                        <Alert type="info">
                            <h2 className='walkthrough'>Welcome to the Math ANEX Assessment Administration Guide!</h2>
                            <div>
                                <Button
                                    style={{
                                        padding: '12px 40px',
                                        height: 'auto',
                                        fontSize: '20px',
                                    }}
                                    type="success"
                                    text="Print Me!"
                                    onClick={() => window.print()}
                                />
                            </div>
                        </Alert>
                    </div>
                    <p className="centered">
                        <span className="doc-title">Administering The {assessment_name} - {this.state.district.name}</span>
                    </p>
                    {show_window == true && (
                    <p className="centered">
                        <span className="bold math-anex-blue">Assessment Window: {date_window_opens} - {date_window_closes}</span>
                    </p>
                    )}
                    
                    <p className="text-tight-spacing">
                        <span className="bold">Friendly Reminder</span>
                    </p>
                    <p className="body-text-spacing">
                        <span>The {assessment_name} is designed to be a tool </span>
                        <span className="bold math-anex-green">for</span>
                        <span>
                        &nbsp;teachers. It is not meant to be used for a grade. It’s a unique
                        opportunity to get insights into{" "}
                        </span>
                        <span className="bold math-anex-green">how your students are thinking</span>
                        <span>
                        &nbsp;about essential math concepts so you can help build on their current
                        understanding.
                        </span>
                    </p>
                    <div style={{ display: "flex" }}>
                        <div>
                            <h2 className="text-tight-spacing walkthrough">
                                The Only Links You’ll Need
                            </h2>
                            <ul className="bullet-wrapper">
                                <li className="text-tight-spacing">
                                <span><b>ASSESSMENT LINK:&nbsp;</b></span>
                                <span className="link-text">
                                    <a
                                    href={proctor_url}
                                    >
                                    This is the link
                                    </a>
                                </span>
                                <span>&nbsp;to push out to all students.</span>
                                <span><br />OR project this shortened link or QR code: </span>
                                <span className="big-link">{full_proctor_url}</span>
                                <span><br />(these will all direct students to the assessment)</span>
                                {rostering_source != null && (
                                    <ul className='bullet-wrapper'>
                                        <li className="text-tight-spacing li-bullet-1">
                                        <span>
                                            Students will be directed to log in to Math ANEX using {rostering_source}
                                        </span>
                                        </li>
                                        <li className="text-tight-spacing li-bullet-1">
                                        <span>
                                            If students experience issues logging in with {rostering_source}, they can enter
                                            their name, teacher, and school and proceed with the assessment.
                                        </span>
                                        </li>
                                        <li className="text-tight-spacing li-bullet-1">
                                        <span>
                                            For common troubleshooting questions and to see more about the student login experience,{" "}
                                        </span>
                                        <span className="link-text">
                                            <a
                                            href="https://docs.google.com/presentation/d/1Rqk0x8GExG6cVgw-_6yAbH7uh7a4AurGu0hj5JOJpUs/edit?usp%3Dsharing&sa=D&source=editors&ust=1702074732212525&usg=AOvVaw0wjU9JsQK688IChTgeACLt"
                                            >
                                            click here
                                            </a>
                                        </span>
                                        </li>
                                    </ul>
                                )}

                                {rostering_source == null && (
                                    <ul className='bullet-wrapper'>
                                        <li className="text-tight-spacing li-bullet-1">
                                        <span>
                                            Students can login using their <b>Name and 5-digit  Student ID #</b> - this will match them in our system and save their work as they move through the assessment.
                                        </span>
                                        </li>
                                        <li className="text-tight-spacing li-bullet-1">
                                        <span>
                                            If their name and ID don’t match the system, there will be a prompt to choose school and teacher so we can match them later. Students can still take the assessment,  but in this case, if students click out of the browser their work will not autosave since they aren’t “logged in.”
                                        </span>
                                        </li>
                                    </ul>
                                )}
                                
                                </li>
                                
                                <li>
                                    <span>
                                        <b>PRINTABLE MATERIALS:&nbsp;</b>To print out paper-pencil versions of the assessment, &nbsp;
                                    </span>
                                    <span className="link-text">
                                        <a
                                            href={"/assessment_printouts/" + this.state.district.id}
                                        >
                                            click here
                                        </a>
                                    </span>
                                </li>
                                <li className="text-tight-spacing">
                                    <span>
                                        <b>PARTICIPATION LINK:&nbsp;</b>Once the assessment window opens, you will be able to monitor student
                                        progress &amp; participation by logging in to &nbsp;
                                    </span>
                                    <span className="link-text">
                                        <a
                                        href={BASE_DOMAIN}
                                        >
                                        mathanex.com
                                        </a>
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <QRCode
                        size={256}
                        style={{  width: "50%", marginTop: "20px",
                        marginBottom: "20px",
                        marginRight: "20px",
                        marginLeft: "20px" }}
                        value={full_proctor_url}
                        viewBox={`0 0 256 256`}
                        fgColor='#4a86e8'
                        />
                    </div>
                    <h2 className="text-tight-spacing walkthrough">
                        <span>The Assessment Is Available In 4 Languages</span>
                    </h2>
                    <ul className="bullet-wrapper">
                        <li className="body-text-spacing">
                        <span>
                            Students can take the {assessment_name} in English, Spanish, Vietnamese, or Tagalog.
                            <br />
                            They can select their preferred language at the beginning of the
                            assessment. Printables in the various languages are available at the Printable Link above. 
                        </span>
                        </li>
                    </ul>
                    <h2 className="text-tight-spacing walkthrough">
                        <span>Optional Prompt To Read To Your Students</span>
                    </h2>
                    <ul className="body-text-spacing">
                        <span className="italics">
                        “Today you will be taking a short math assessment called The {assessment_name}. This
                        will help me better understand how you are thinking about math. Some of
                        the questions will seem familiar and some of them might feel new. Please
                        do your best so that I can have an authentic understanding of how to best
                        support you as a mathematician.”
                        </span>
                    </ul>
                    <p className="text-tight-spacing">
                        <span className="italics">
                        Still have questions? Check out the FAQ section below or
                        </span>
                        <span className="italics">&nbsp;</span>
                        <span className="italics link-text">
                            <a href="mailto:MathANEXsupport@amplify.com">
                                reach out to us here!
                            </a>
                        </span>
                    </p>
                    <h1>
                        <span>FAQ</span>
                    </h1>
                    <h2 className="text-tight-spacing walkthrough">
                        <span>
                        How do students take the {assessment_name}?
                        </span>
                    </h2>
                    <ul className="body-text-spacing">
                        <span>
                            Students will take the {assessment_name} online, using the assessment link
                            provided above. Students can take the {assessment_name} on a variety of devices
                            (iPad, Chromebook, laptop, phone, etc.). Printable versions of the
                            assessments are also provided above to help with specific student
                            accommodations. Responses written on paper will need to be manually 
                            entered into the assessment using the student’s name and ID.
                        </span>
                    </ul>
                    <h2 className="text-tight-spacing walkthrough">
                        <span>
                        What if students are absent?
                        </span>
                    </h2>
                    <ul className="body-text-spacing">
                        <span>
                            We understand the challenges with students being absent and
                            want to provide as much flexibility as possible. There is a window
                            to complete the assessment. Students who are able can take the
                            assessment from home. Students who are ill and unable to do
                            homework during the window do not need to take the {assessment_name}.
                        </span>
                    </ul>
                    <h2 className="text-tight-spacing walkthrough">
                        <span>
                        Can students use calculators or scratch paper?
                        </span>
                    </h2>
                    <ul className="body-text-spacing">
                        <span>
                            All students are welcome to use physical scratch paper or the
                            digital scratch paper that is embedded in the assessment platform.
                            (Note: use of the digital scratch paper is optional and unscored).
                            Students in Grades 6 and above also have a calculator available in
                            the platform or may use their own. Students in Grades 3-5 should
                            not use calculators.
                        </span>
                    </ul>
                    <h2 className="text-tight-spacing walkthrough">
                        <span>
                        Can I help with vocabulary or language issues?
                        </span>
                    </h2>
                    <ul className="body-text-spacing">
                        <span>
                            Yes, feel free to answer any questions to help students
                            understand and gain access to the items. Please refrain from
                            helping with the mathematics or giving "hints" for how to solve
                            the items or what to write.
                        </span>
                    </ul>
                    <h2 className="text-tight-spacing walkthrough">
                        <span>
                        How long can students have to complete the assessment?
                        </span>
                    </h2>
                    <ul className="body-text-spacing">
                        <span>
                            There is no real time limit. Most students should finish within
                            30 minutes. Since we want to minimize the loss of instruction
                            time, do not take more than one class period to complete the
                            assessment. However, please honor students’ existing 504s and IEPs
                            if these students need additional time.
                        </span>
                    </ul>
                    <h2 className="text-tight-spacing walkthrough">
                        <span>
                        What assessment should my accelerated/compacted students take?
                        </span>
                    </h2>
                    <ul className="body-text-spacing">
                        <span>
                            This is up to the district to decide, but we suggest students in compaction courses take the {assessment_name} that is most closely aligned with their current
                            course.
                        </span>
                    </ul>
                </Container>
            );
        }

        const header_height = 62;

        const main_content_style = {
            transition: '.2s margin-left',
            marginLeft: '76px',
            height: `${this.state.height}px`,
        };

        const result_style = {
            background: 'white',
            height: `${this.state.height - header_height}px`,
            transition: '.2s margin',
            overflowY: 'auto',
            paddingTop: '20px',
        };

        return (
            <Wrapper
                loaded={this.state.loaded}
                style={{ marginTop: '0px' }}
            >
                <div className="reporting-dashboard">
                    <SideNavbar
                        height={this.state.height}
                        district={this.state.district}
                    />

                    <div style={main_content_style} className="sad">
                        <Header
                            page="Assessment Administration Guide"
                            width={this.state.width}
                            pdf_name="Assessment Administration Guide"
                        />

                        <div style={result_style} className='no-style report-container'>
                            {/* Main Content Display */}

                            {content}

                            <div style={{ clear: 'both', width: '100%' }} />
                        </div>
                    </div>
                </div>
            </Wrapper>
        );
    }
}

export default AssessmentAdministrationGuide;
