import React, { Component } from 'react';
import ajaxWrapper from 'base/ajax.js';

import { FormWithChildren, Select, Alert } from 'library';
import { format_time_difference } from 'functions';

class ParticipationRow extends Component {
    constructor(props) {
        super(props);

        this.mark_incomplete = this.mark_incomplete.bind(this);
    }

    mark_incomplete() {
        ajaxWrapper(
            'POST',
            `/api/home/studentassessment/${this.props.student_assessment['id']}/`,
            { completed: false },
            this.props.refresh
        );
    }

    render() {
        let teacher = this.props.teacher;

        let percentage = '0';
        if (teacher['total_students']) {
            percentage = (
                (teacher['participating_students'] /
                    teacher['total_students']) *
                100
            ).toFixed(0);
        }

        let teacher_name = `${teacher['last_name']}`;
        if (teacher['first_name']) {
            teacher_name = `${teacher['last_name']}, ${teacher['first_name']}`;
        }

        return (
            <tr>
                <td>{teacher_name}</td>
                <td style={{ textAlign: 'right' }}>
                    {teacher['participating_students']}
                </td>
                <td style={{ textAlign: 'right' }}>
                    {teacher['total_students']}
                </td>
                <td style={{ textAlign: 'right' }}>{`${percentage}%`}</td>
            </tr>
        );
    }
}

export default class StaffParticipation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filtered_school: null,
        };
    }

    render() {
        let participation_rows = [];
        let header_text = 'Results By Teacher';

        if (this.props.schools.length == 1 || this.state.filtered_school) {
            let total_teacher = {
                last_name: 'ALL',
                participating_students: 0,
                total_students: 0,
            };
            for (let item of this.props.teachers) {
                let teacher = item['districtuser'];

                if (this.state.filtered_school) {
                    let valid_teachers =
                        this.props.school_lookup[this.state.filtered_school][
                            'teachers'
                        ];
                    if (!valid_teachers.includes(teacher['id'])) {
                        continue;
                    }
                }

                participation_rows.push(<ParticipationRow teacher={teacher} />);
                total_teacher['participating_students'] +=
                    teacher['participating_students'];
                total_teacher['total_students'] += teacher['total_students'];
            }

            //participation_rows.unshift(
            //    <ParticipationRow teacher={total_teacher} />
            //);
        } else {
            header_text = 'Results By School';
            let total_school = {
                last_name: 'ALL',
                participating_students: 0,
                total_students: 0,
            };
            for (let item of this.props.schools) {
                let school = item['school'];
                school['last_name'] = school['name'];

                participation_rows.push(<ParticipationRow teacher={school} />);
                total_school['participating_students'] +=
                    school['participating_students'];
                total_school['total_students'] += school['total_students'];
            }

            participation_rows.unshift(
                <ParticipationRow teacher={total_school} />
            );
        }

        let table_headers = (
            <tr>
                <th width="20">Name</th>
                <th width="20" style={{ textAlign: 'right' }}>
                    Participating Students
                </th>
                <th width="20" style={{ textAlign: 'right' }}>
                    Total Students
                </th>
                <th width="20" style={{ textAlign: 'right' }}>
                    % Participation
                </th>
            </tr>
        );

        let filters = (
            <FormWithChildren
                autoSetGlobalState
                globalStateName="filter"
                setGlobalState={(name, state) => this.setState(state)}
            >
                <Select
                    label="School"
                    name="filtered_school"
                    options={this.props.school_options}
                    style={{
                        width: '200px',
                        display: 'inline-block',
                        marginRight: '10px',
                    }}
                />
            </FormWithChildren>
        );

        let elapsed =
            this.props.current_date.getTime() - this.props.open_date.getTime();

        let no_students_error = null;
        let participation_content = null;
        if (participation_rows.length == 0) {
            no_students_error = (
                <Alert
                    type="warning"
                    text="No Teachers were found in your schools.  If this is in error, please
                    contact Math Anex Support at MathANEXsupport@amplify.com."
                />
            );
        } else {
            participation_content = (
                <div className="col-12">
                    <div>
                        <div className="header h2">{header_text}</div>
                        <table className="table simple-card-table">
                            {table_headers}
                            {participation_rows}
                        </table>
                    </div>
                </div>
            );
        }

        return (
            <div className="row col-12">
                <div className="col-12">
                    <div style={{ float: 'right' }}>{filters}</div>
                    <div className="header h1">Assessment Participation</div>
                    <div>
                        {`This page has been open for ${format_time_difference(
                            elapsed
                        )}`}
                        <a
                            className="purple-button-block"
                            href="."
                            style={{
                                marginLeft: '10px',
                            }}
                        >
                            Refresh Data
                        </a>
                    </div>
                    <div>
                        <br />
                    </div>
                </div>

                {no_students_error}
                {participation_content}
            </div>
        );
    }
}
